.new-pricing-page-container {
    position: relative;
    user-select: none;
    overflow: hidden;
    width: 100%;
}

.all-credit-cards {
    width: 100%;
    height: 80px;
    border-radius: 16px;
}

.left-padding-text-container {
    white-space: break-spaces;
    min-width: 350px;
    max-width: 350px;
}

.pricing-swiper-component {
    height: 170px;
}

.pricing-page-slide-item{
    width: 100vw;
}

.pricing-slider-component{
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.pricing-container{
    padding: 5px;
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9998;
}

.bottom-of-the-page-container {
    display: flex;
    flex-direction: row;
    padding: 80px 20px 60px;
    gap: 120px;
    width: 100%;
    overflow: hidden;
    position: relative;
    z-index: 2;
    align-items: center;
    justify-content: space-around;
}

.top-pricing-row-container {
    display: flex;
    flex-direction: row;
    gap: 120px;
    width: 100%;
    padding: 100px 20px 40px;
    align-items: center;
    justify-content: space-around;
    border-bottom: 1px solid #2D2D2D;
    background-color: #131313;
    position: relative;
    z-index: 2;
}

.anime-girl {
    position: absolute;
    right: 0;
    bottom: 0;
    height: fit-content;
    width: auto;
    max-height: 100%;
    z-index: -1;
    opacity: 0.20;
}

.girl-real {
    position: absolute;
    right: 0;
    left: -60px;
    bottom: 0;
    width: auto;
    max-height: 100%;
    z-index: -1;
    opacity: 0.20;
}

.modal-pricing {
    display: flex;
    position: fixed;
    top: 50%;
    width: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    padding: 20px;
    border-radius: 5px;
}

.modal-header-pricing {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 50px;
}

.modal-content-pricing {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    gap: 80px;
}

.pricing-container-top-column {
    display: flex;
    flex-direction: column;
    /*margin-left: auto;*/
    align-items: center;
    gap: 10px;
}

.top-timer-timer-general {
    position: relative;
    display: flex;
}

.top-timer-timer {
    display: flex;
    align-items: center;
    align-self: flex-end;
    justify-content: center;
    font-weight: bold;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    background: linear-gradient(180deg, rgba(36, 36, 36, 0.6) 0%, rgba(0, 0, 0, 0.2) 100%);
    border-radius: 24px;
    padding: 4px 16px;
}

.top-timer-padding {
    background: linear-gradient(180deg, rgba(36, 36, 36, 0.61) 0%, rgba(0, 0, 0, 0.65) 100%);
    position: relative;
    display: flex;
    border-radius: 24px;
    font-style: normal;
    font-weight: 700;
    font-size: 1em;
    opacity: 0.9;
    color: #FFFFFF;
    padding: 8px 16px;
    margin-right: 50px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.top-timer-timer span {
    display: inline-block;
    margin: 0;
    font-size: 0.90em;
}

.top-timer-timer small {
    margin: 0;
    font-size: 0.7em;
}

.top-timer-timer span.label {
    font-size: 0.8em;
    font-weight: normal;
}

.top-timer-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 60px;
    cursor: pointer;
    color: white;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    z-index: 99;
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    background: #6C3EA6;
}


.top-timer-container-chat {
    width: 100vw;
    height: 60px;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    color: white;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    user-select: none;
    text-align: left;
    border-radius: 0;
    background: #6C3EA6;
}

.top-timer-text {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    font-weight: bolder;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    letter-spacing: 0.05em;
    color: #FFFFFF;
}

.top-timer-text svg {
    height: 18px;
    width: 24px;
    margin-right: 5px;
    margin-bottom: 5px;
}


.new-pricing-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: rgba(19, 19, 19, 0.8);
    padding-top: 85px;
    overflow-x: hidden;
    position: absolute;

}

.pricing-cost-text {
    font-weight: 800;
    font-size: 1.25em;
    white-space: nowrap;

}

.pricing-cost-text-price {
    font-weight: 800;
    font-size: 1.25em;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
}

.pricing-cost-text-main {
    display: flex;
    font-size: 0.7em;
    flex-direction: row;
    align-items: flex-end;
    margin-left: 5px;
}

.crossed-out {
    text-decoration: line-through;
    color: #727272;
}

.buy-right-now-button-crypto,
.buy-right-now-button {
    width: 100%;
    height: 60px;
    gap: 10px;
    margin-top: 20px;
    display: flex;
    background: linear-gradient(102.84deg, #A281F2 41.88%, #998CB9 66.26%);
    border-radius: 16px;
    font-style: normal;
    font-weight: 600;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
    color: #FFFFFF;
}

.buy-right-now-button{
    padding-right: 20px;
}

.buy-right-now-button-crypto {
    gap: 5px;
    background: linear-gradient(102.84deg, #F7931A 41.88%, #E3A036 66.26%);
}

.buy-right-now-button-crypto:hover,
.buy-right-now-button:hover {
    opacity: 0.95;
}

.buy-right-now-button-crypto svg {
    height: 48px;
    width: 42px;
}

.buy-right-now-button svg {
    height: 48px;
    width: 48px;
}

.buy-right-now-button-credit {
    width: 60%;
    height: 50px;
    min-width: 160px;
    white-space: nowrap;
    display: flex;
    margin-top: 30px;
    background: linear-gradient(102.84deg, #A281F2 41.88%, #998CB9 66.26%);
    border-radius: 16px;
    font-style: normal;
    font-weight: 600;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    line-height: 38px;
    color: #FFFFFF;
}

.buy-right-now-button-credit:hover {
    opacity: 0.95;
}

.buy-right-now-button-credit-addons {
    width: 60%;
    margin-top: 15px;
    min-height: 35px;
    min-width: 160px;
    white-space: nowrap;
    display: flex;
    background: #b5a2e0 linear-gradient(102.84deg, #9072D9 41.88%, #8573B0 66.26%);
    border-radius: 12px;
    font-style: normal;
    font-weight: 600;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    color: #FFFFFF;
}

.buy-right-now-button-credit-addons:hover {
    opacity: 0.95;
}

.new-pricing-subtitle {
    color: #C4C4C4;
    font-size: 1em;
}

.highlight-text-price {
    background: linear-gradient(93.29deg, #e2cafa 25.27%, #a489ec 68.17%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-weight: 700;
}

.most-popular-pin {
    gap: 5px;
    font-weight: 600;
    flex-direction: row;
    display: flex;
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 0.5em;
    padding: 2px 4px;
    background: linear-gradient(270deg, #976DFD 0%, rgba(151, 109, 253, 0) 100%);
    border: 1px solid #DFD1FF;
    border-radius: 16px;
    align-items: center;
}

.most-popular-pin svg {
    height: 16px;
}

.new-toggle-pricing {
    display: flex;
    gap: 25px;
    flex-direction: column;
    align-self: flex-start;
    width: fit-content;
}

.get-pricing-final {
    display: flex;
    color: #8A8A8A;
    font-size: 0.75em;
    margin-top: 10px;
}

.new-pricing-btn {
    padding: 30px 25px;
    width: 350px;
    height: auto;
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
    color: white;
    border: 2px solid #636363;
    cursor: pointer;
    font-size: 1.25em;
    transition: all 0.3s ease;
    border-radius: 16px;
    background: linear-gradient(90deg, rgba(91, 69, 141, 0.8) 0%, rgba(70, 57, 102, 0.2) 98.5%), #131313;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
}

.pricing-container-general {
    display: flex;
    flex-direction: row;
}

.discount-amount-pricing {
    position: absolute;
    top: -12px;
    border-radius: 24px;
    background-color: #976DFD;
    color: #fff;
    padding: 4px 6px;
    font-weight: 700;
    font-size: 0.65em;
    transform-origin: left top;
    z-index: 1;
}

.pricing-subtitle-text {
    color: #8A8A8A;
    font-size: 0.6em;
    margin-top: 15px;
}

.new-pricing-btn-active {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    border: 2px solid #EDE5FF;
}

.pricing-container-general,
.pricing-title-text {
    font-weight: 700;
    font-size: 0.95em;
}

@media screen and (min-width: 0px) and (max-width: 680px) {
    .all-credit-cards {
        width: 75vw;
        height: 60px;
        border-radius: 16px;
    }
    .top-pricing-row-container{
        gap: 60px;
    }

    .top-timer-container-chat {
        border-radius: 0 0 16px 16px;
    }

    .modal-pricing {
        display: flex;
        flex-direction: row;
        position: relative;
        align-items: center;
        top: 50%;
        width: 100%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9999;
        padding: 20px;
        border-radius: 5px;
    }

    .bottom-of-the-page-container,
    .top-pricing-row-container {
        display: flex;
        padding: 100px 20px 24px;
        width: 100%;
        flex-direction: column;
        justify-content: center;
    }

    .top-timer-text {
        font-size: .75em;
        white-space: break-spaces;
    }

    .top-timer-padding {
        font-size: .75em;
        white-space: nowrap;
        margin-right: 10px;
    }


    .modal-header-pricing {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        padding: 0 15px 140px;
        margin-bottom: 0;
    }

    .swiper-button-prev, .swiper-button-next {
        color: #fff !important;
        padding: 10px;
        border-radius: 50%; /* Circular buttons */
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
        cursor: pointer;
    }

    .swiper-button-prev {
        left: 10px;

    }

    .swiper-button-next {
        right: 10px;
    }

    .other-plans-text {
        margin: 0 auto 20px;
        font-size: 1.75em;
        display: flex;
        flex-direction: row;
        font-weight: 700;
        width: 100%;
        color: white;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
    }

    .bottom-of-the-page-container {
        padding-top: 40px;
        gap: 20px;
    }

    .new-pricing-subtitle {
        color: #C4C4C4;
        font-size: .9em;
        white-space: break-spaces;
    }

    .card-container-main-premium {
        display: flex;
        gap: 20px;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 20px;
    }

    .buy-right-now-button-credit-addons,
    .buy-right-now-button-credit {
        width: unset;
        max-width: 200px;
        height: 35px;
        display: flex;
        font-size: 1em;
        border-radius: 12px;
    }

    .pricing-container-top-column {
        margin-top: -40px;
    }

    .buy-right-now-button-crypto,
    .buy-right-now-button {
        width: 75vw;
        margin-top: 0;
        gap: 10px;
        height: 50px;
        font-size: 1em;
        white-space: nowrap;
        padding-right: 0;
    }
    
    .buy-right-now-button {
        padding-right: 10px;
    }

    .buy-right-now-button-crypto {
        gap: 5px;
    }

    .new-pricing-btn {
        height: auto;
        padding: 20px;
        margin-top: 20px;
        max-height: 150px;
        min-width: unset;
        max-width: 450px;
        width: unset;
    }

    .left-padding-text-container {
        white-space: break-spaces;
        width: 100vw;
        justify-content: center;
        display: flex;
        min-width: 350px;
        max-width: 350px;
        flex-direction: column;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 30px;
    }

    .top-timer-timer {
        margin-right: 0;
        padding: 4px 12px;
        font-size: 0.8em;
    }

    .pricing-cost-text-main {
        white-space: nowrap;
    }

    .new-toggle-pricing {
        width: 100vw;
        align-self: center;
    }

    .modal-content-pricing {
        flex-direction: row;
        gap: 10px;
    }

    .anime-girl {
        position: absolute;
        bottom: 0;
        right: 0;
        height: fit-content;
        width: auto;
        max-height: 85%;
        z-index: -1;
        opacity: 0.15;
    }

    .girl-real {
        position: absolute;
        right: 0;
        left: -120px;
        bottom: 0;
        width: auto;
        max-height: 85%;
        z-index: -1;
        opacity: 0.15;
    }
}
