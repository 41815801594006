.resume-plan-modal {
    max-width: 550px;
}

.resume-plan-content {
    display: flex;
    flex-direction: column;
    align-items: center;    
}

.resume-plan-title {
    font-size: 1.5em;
    font-weight: 700;
    color: #fff;
    margin-bottom: 15px;
}

.resume-plan-description {
    color: #c4c4c4;
    font-size: 1em;
}

.resume-plan-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    margin-top: 20px;
    width: 100%;
}

.resume-plan-actions button {
    flex: 1;
}

.resume-success-message {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: rgba(151, 109, 253, 0.2);
    border-radius: 12px;
    padding: 15px;
    margin-top: 10px;
    color: #fff;
    font-weight: 600;
}

.resume-success-message svg {
    height: 24px;
    width: 24px;
    stroke-width: 2px;
    color: #976DFD;
}

@media (max-width: 680px) {
    .resume-plan-modal {
        width: 90%;
        max-width: 450px;
    }
    
    .resume-plan-actions {
        flex-direction: column;
    }
}
