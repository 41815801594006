.subscription-plan{
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 25px;
}

.modal-plan{
    position: relative;
    background:  linear-gradient(97.28deg, rgba(117, 19, 250, 0.3) 33.62%, rgba(191, 63, 251, 0.3) 75.64%),url("./files/background-hearts.svg"), #131313;
    border-radius: 12px;
    width: 100%;
    /* height: 250px; */
    padding: 20px;
}

.features-list{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 20px;
    width: 100%;
    color: #fff;
    font-weight: 700;
    font-size: 1em;
    gap: 15px;
}

.feature-child{
    display: flex;
    align-items: center;
    gap: 5px;
}

.feature-child svg{
    height: 18px;
    width: 18px;
    stroke-width: 2px;
}

.modal-plan-desc{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.modal-desc-text{
    color: #c4c4c4;
    font-weight: 500;
    font-size: 1.15em;
}

.modal-desc-text-2{
    color: #fff;
    font-weight: 700;
    font-size: 1.35em;
}

.current-plan{
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 1em;
    font-weight: 600;
    border-radius: 24px;
    background-color: #976DFD;
    padding: 8px;
}