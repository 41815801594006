.confirm-cancellation-modal {
    max-width: 550px;
}

.cancellation-success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
    margin: 25px 0;
}

.cancellation-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    background: linear-gradient(97.28deg, rgba(117, 19, 250, 0.2) 33.62%, rgba(191, 63, 251, 0.2) 75.64%), #131313;
    border-radius: 50%;
    border: 2px solid #976DFD;
}

.cancellation-icon svg {
    height: 40px;
    width: 40px;
    stroke-width: 2px;
    color: #976DFD;
}

.cancellation-message {
    text-align: center;
    color: #fff;
    font-size: 1.2em;
    font-weight: 600;
    line-height: 1.5;
}

.cancellation-details {
    text-align: center;
    color: #c4c4c4;
    font-size: 1em;
    font-weight: 500;
    line-height: 1.5;
    padding: 0 20px;
}

.cancellation-date {
    display: inline-block;
    font-weight: 700;
    color: #fff;
}

.cancellation-actions {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
}

.cancellation-actions button {
    width: 50%;
    min-width: 150px;
}

@media (max-width: 680px) {
    .confirm-cancellation-modal {
        width: 90%;
        max-width: 450px;
    }
    
    .cancellation-icon {
        width: 70px;
        height: 70px;
    }
    
    .cancellation-icon svg {
        height: 35px;
        width: 35px;
    }
    
    .cancellation-message {
        font-size: 1.1em;
    }
    
    .cancellation-details {
        font-size: 0.9em;
    }
    
    .cancellation-actions button {
        width: 70%;
    }
}
