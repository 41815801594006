.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050; /* Ensures modal is above most other content */
    /*backdrop-filter: blur(10px);*/
}

.confirmation-phrase-container {
    margin-top: 24px;
    animation: slideDown 0.3s ease-in-out;
}

.confirmation-phrase-input {
    width: 100%;
    padding: 12px 16px;
    margin-top: 8px;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    font-size: 14px;
    transition: all 0.2s ease;
}

.confirmation-phrase-input:focus {
    outline: none;
    border-color: #805ad5;
    box-shadow: 0 0 0 3px rgba(128, 90, 213, 0.1);
}

.notification-button-main.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
}

.notification-button-main:not(:disabled):hover {
    background: #915ae8;
}

.notification-button-main:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
}


@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.automatic-card-detail {
    display: flex;
    width: 100%;
    height: 60px;
    background: #242424;
    padding: 4px 8px;
    border-radius: 8px;
    align-items: center;
    cursor: pointer;
    transition: background 0.3s ease;
}

.automatic-card-detail:hover {
    background: #2c2c2c;
}

.instant-transaction-cost {
    display: flex;
    flex-direction: row;
    color: #c4c4c4;
    margin-left: auto;
    align-items: center;
    gap: 10px;
}

.instant-transaction-cost svg {
    width: 20px;
    height: 20px;
}

.modal-show-again-button {
    font-weight: 700;
    font-size: 1em;
    color: #C4C4C4;
    cursor: pointer;
    width: 100%;
    text-align: center;
    margin-top: 10px;
}

.modal-show-again-button:hover {
    color: #BEBEBE;

}

.loading-modal, .failure-modal {
    position: relative; /* Centering is handled by the overlay */
    width: auto; /* Adjust based on your content */
    max-width: 90%; /* Prevents the modal from being too wide on large screens */
    margin: auto; /* Helps with centering */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: adds shadow for depth */
}

.modal-image-generation-mode {

    position: absolute; /* or fixed, depending on your needs */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #131313;
    padding: 25px;
    border-radius: 10px;
    width: 35%;
    color: white;
    display: flex;
    flex-direction: column;
    text-align: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    min-width: 420px;
    max-width: 480px;
    z-index: 9999;
    user-select: none;
}

.modal-content-notification {
    background-color: #131313;
    padding: 20px 30px;
    user-select: none;
    color: white;
    border-style: solid;
    border-radius: 16px;
    border-width: 1px;
    border-color: #242424;
    min-width: 420px;
    max-width: 480px;
    width: 55vw;
    max-height: 80%;
    overflow: hidden;
}

.modal-content-notification-discord {
    background: linear-gradient(275.36deg, rgba(0, 71, 255, 0.2) -5%, rgba(0, 0, 0, 0) 59.08%), linear-gradient(74.11deg, rgba(15, 111, 255, 0.106) 18.4%, rgba(0, 0, 0, 0) 48.51%), #1A1A1A;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    padding: 30px;
    user-select: none;
    color: white;
    border-style: solid;
    border-radius: 16px;
    border-width: 1px;
    border-color: #242424;
    min-width: 420px;
    max-width: 480px;
    width: 55vw;
    max-height: 80%;
    overflow: hidden;
}

.modal-content-email-verif {
    background-color: #131313;
    padding: 30px;
    user-select: none;
    color: white;
    border-style: solid;
    border-radius: 16px;
    border-width: 1px;
    border-color: #242424;
    min-width: 420px;
    max-width: 480px;
    width: 55vw;
    max-height: 80%;
    overflow: hidden;
}

.modal-content-notification-join-gc {
    background-color: #131313;
    padding: 25px;
    user-select: none;
    color: white;
    border-style: solid;
    border-radius: 16px;
    border-width: 1px;
    border-color: #242424;
    min-width: 420px;
    max-width: 480px;
    width: 55vw;
    max-height: 60%;
    overflow-y: auto;
}




.checkbox-container {
    display: flex;
    align-items: center;
}

.checkbox-label {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.checkbox-input {
    display: none;
}

.checkbox-square {
    min-width: 24px;
    min-height: 24px;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkbox-svg {
    width: 16px;
    height: 16px;
    fill: none;
    stroke: white;
    stroke-width: 3;
    opacity: 0;
}

.checkbox-input:checked + .checkbox-square {
    background-color: #9072D9;
}

.checkbox-input:checked + .checkbox-square .checkbox-svg {
    opacity: 1;
}




.notification-label {
    font-size: 1.5em;
    font-weight: 700;
    flex-direction: row;
    display: flex;
    width: 100%;
    white-space: normal;
    justify-content: space-between;
    padding-bottom: 20px;
    align-items: center;
}

.notification-label-header-special {

    font-size: 2.0em;
    font-weight: 700;
    flex-direction: row;
    display: flex;
    width: 100%;
    white-space: nowrap;
    justify-content: center;
    padding-bottom: 15px;
    align-items: center;

}

.notification-label-special {
    font-size: 3.45em;
    font-weight: 700;
    flex-direction: row;
    display: flex;
    width: 100%;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
}

.notification-label-special svg {
    width: 72px;
    height: 72px;
    margin-left: 10px;
}

.sublabel-notification {
    color: #C4C4C4;
    font-size: 1em;
    font-weight: 500;
}

.get-a-new-code-button {
    position: relative;
    color: #C4C4C4;
    font-size: 1em;
    font-weight: 500;
    cursor: pointer;
}


.get-a-new-code-button::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    border-radius: 12px;
    background: #3b3b3b; /* Change this to the color of your underline */
    transition: width 0.3s ease-in-out;
}

.get-a-new-code-button:hover::after {
    width: 100%;
}

/* Notifications.css */
.shake {
    animation: shake 0.5s;
}

@keyframes shake {
    0%, 100% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(-5px);
    }
    75% {
        transform: translateX(5px);
    }
}

.colored-purple-text {
    background: linear-gradient(0deg, rgba(196, 196, 196, 0.5), rgba(196, 196, 196, 0.5)),
    linear-gradient(101.6deg, #ad4ee7 50.65%, #ac14e8 86.66%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 1em;
    font-weight: 700;
    font-style: italic;
}

.colored-purple-text-underline {
    position: relative;
    color: #bd6aec;
    font-size: 1em;
    font-weight: 700;
    font-style: italic;
    cursor: pointer;
    transition: color 0.3s ease;
}

.colored-purple-text-underline::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: #bd6aec;
    transform: scaleX(1);
    transform-origin: left;
    transition: transform 0.3s ease;
}

.colored-purple-text-underline:hover {
    color: #ad4ee7;
}

.colored-purple-text-underline:hover::after {
    transform: scaleX(1);
}

.colored-purple-text-underline:hover {
    color: #ad4ee7;
    text-decoration: underline;
}

.gap-between-notification {
    gap: 20px;
    display: flex;
    flex-direction: column;
}

.notification-button-main {
    font-size: 1.05em;
    width: 100%;
    height: 50px;
    color: white;
    font-weight: 550;
    background: #916DE8;
    border-radius: 16px;
    margin-top: 30px;
    margin-bottom: 5px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    display: flex;
    transition: opacity 0.3s ease, background 0.3s ease;
}

.notification-button-main svg {
    width: 32px;
    height: 32px;
    min-height: 32px;
    min-width: 32px;
    margin-right: 10px;
    max-height: 32px;
    max-width: 32px;
}

.notification-button-main:hover {
    opacity: 0.85;
    background: #7d5bd1;
}

.get-a-new-code-button.no-hover::after {
    background: none;
}

.get-a-new-code-button.no-hover:hover::after {
    width: 0;
}

.notification-button-main-gray-email {
    background: #656565;
}

.notification-button-main-gray-email:hover {
    background: #656565;
}

.notification-button-main-gray {
    font-size: 1.05em;
    width: 100%;
    height: 45px;
    color: white;
    font-weight: 550;
    border-radius: 26px;
    cursor: pointer;
}

.notification-button-main-gray:hover {
    opacity: 0.80;
}

.notification-button-main.cooldown {
    background: #727272;
    cursor: not-allowed;
}

.modal-close-icon-notification svg {
    stroke: white;
    height: 16px;
    cursor: pointer;
}


.bolder-white-text-notification {
    font-weight: 900;
    font-style: italic;
    line-height: 36px;
    background: linear-gradient(93.24deg, #F7F7F7 2.68%, #E0E0E0 6.25%, #B4B4B4 62.16%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.color-text-test {
    color: white;

}

.code-input-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0
}


.code-input {
    width: 50px;
    height: 60px;
    font-size: 24px;
    text-align: center;
    background-color: #171717;
    color: #C4C4C4;
    border-radius: 12px;
    border: 1px solid #696969;

}

.model-selection-container {
    display: flex;
    justify-content: space-between;
    margin: 5px 0 5px;
    gap: 20px;
}

.model-button {
    padding: 20px 20px;
    border-radius: 10px;
    border: 2px solid #ccc;
    background-color: #171717;
    color: #BEBEBE;
    font-weight: bolder;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.model-button.selected {
    background-color: #ccc;
    color: white;
    border: 2px solid #ffffff;
}

.model-button:hover {
    background-color: #363636;
}

.model-button.selected:hover {
    background-color: #6d57a2;

}

.footer-button-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
}


.save-setting-button-big {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 100%;
    border-radius: 8px;
    background-color: #916DE8;
    cursor: pointer;
    font-size: 1em;
    font-weight: 700;
    color: white;
    padding: 20px;
    transition: background-color 0.3s ease, opacity 0.3s ease

}

.save-setting-button-big:hover {
    background-color: #7d5bd1;
    opacity: 0.95;
}

.discord-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 100%;
    max-width: 110px;
    border-radius: 8px;
    background-color: #6377BE;
    cursor: pointer;
    font-size: 1.15em;
    font-weight: 800;
    color: white;
    padding: 20px;
    transition: background-color 0.3s ease, opacity 0.3s ease
}

.discord-button:hover {
    background-color: #4f63a0;
    opacity: 0.95;
}

.discord-button svg {
    min-width: 18px;
    height: 24px;
    margin-right: 5px;
}

@media (max-width: 680px) {
    .modal-image-generation-mode {
        width: 35%;
        min-width: 350px;
        max-width: 450px;
    }

    .save-setting-button-big {
        font-size: 0.85em !important;
        white-space: nowrap;
    }

    .discord-button {
        font-size: 0.85em !important;
    }

    .modal-backdrop {
        align-items: flex-end;
    }

    .modal-content-notification {
        min-width: 100%;
        padding: 25px;
        max-width: 420px;
        border-radius: 16px 16px 0 0;
    }

    .modal-content-notification-discord {
        min-width: 100%;
        padding: 25px;
        max-width: 500px;
    }

    .modal-content-email-verif {
        width: 100%;
        padding: 25px;
        min-width: 340px;
        max-width: 420px;
    }

    .modal-content-notification-join-gc {
        min-width: 80%;
    }

    .notification-button-main {
        margin-top: 20px;
        margin-bottom: 0;

    }

    .code-input {
        width: 45px;
        height: 55px;
    }

}