.final-modal-main {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    height: 100%;
}

.final-modal-column-left{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 35%;
    gap: 10px;
}

.final-modal-column-right{
    display: flex;
    flex-direction: column;
    width: 65%;
    gap: 10px;
}

.final-image-main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    gap: 10px;
    background-color: rgba(91, 91, 91, 0.2);
    width: 100%;
    height: 160px;
}

.final-image{
    object-fit: cover;
    object-position: top;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid #c4c4c4;
}

.final-message{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    background-color: #6F46D1;
    border-radius: 0 12px 12px 12px;
    padding: 10px;
    margin-top: 60px;
}