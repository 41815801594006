.pause-subscription-modal {
    max-width: 550px;
}

.pause-options-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    margin: 25px 0;
}

.pause-option {
    position: relative;
    background: linear-gradient(97.28deg, rgba(117, 19, 250, 0.2) 33.62%, rgba(191, 63, 251, 0.2) 75.64%), #131313;
    border: 2px solid #393939;
    border-radius: 12px;
    padding: 20px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.pause-option:hover {
    background: linear-gradient(97.28deg, rgba(117, 19, 250, 0.3) 33.62%, rgba(191, 63, 251, 0.3) 75.64%), #131313;
    transform: translateY(-2px);
}

.pause-option.selected {
    border: 2px solid #976DFD;
    background: linear-gradient(97.28deg, rgba(117, 19, 250, 0.4) 33.62%, rgba(191, 63, 251, 0.4) 75.64%), #131313;
}

.pause-option-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.pause-duration {
    font-size: 1.35em;
    font-weight: 700;
    color: #fff;
}

.pause-checkmark {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pause-checkmark svg {
    height: 20px;
    width: 20px;
    stroke-width: 2px;
}

.pause-description {
    color: #c4c4c4;
    font-size: 1em;
    font-weight: 500;
}

.pause-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
}

.pause-actions button {
    flex: 1;
}

.notification-button-main.disabled {
    background-color: #656565;
    opacity: 0.7;
    cursor: not-allowed;
}

.pause-success-message {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: rgba(151, 109, 253, 0.2);
    border-radius: 12px;
    padding: 15px;
    margin-top: 10px;
    color: #fff;
    font-weight: 600;
}

.pause-success-message svg {
    height: 24px;
    width: 24px;
    stroke-width: 2px;
    color: #976DFD;
}

@media (max-width: 680px) {
    .pause-subscription-modal {
        width: 90%;
        max-width: 450px;
    }
    
    .pause-actions {
        flex-direction: column;
    }
    
    .pause-duration {
        font-size: 1.2em;
    }
}
