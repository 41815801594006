.consider-main-component {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    gap: 10px;
    margin-top: 20px;
}

.consider-left-column {
    display: flex;
    flex-direction: column;
    width: 65%;
    gap: 10px;
}


.consider-right-column {
    display: flex;
    flex-direction: column;
    width: 35%;
    gap: 10px;
}

.consider-double {
    display: flex;
    flex-direction: row;
    gap: 10px;
}


.consider-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 65px;
    background:  linear-gradient(340.47deg, rgba(117, 19, 250, 0.45) -42.11%, rgba(191, 63, 251, 0.45) 112.48%),url("./files/background-hearts.svg"), #131313;
    border-radius: 12px;
    width: 100%;
}

.consider-block.half {
    width: 50%;
}

.consider-block.height {
    height: unset;
}

.consider-block:hover, .consider-block.half:hover, .consider-block.height:hover {
    opacity: 0.90;
    scale: 1.05;
    transition: all 0.15s ease-in-out;
}

.consider-text{
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    gap: 10px;
}

.consider-text svg{
    height: 24px;
    width: 24px;
    stroke-width: 2px;
}

.consider-text.top{
    font-size: 1.1em;
    font-weight: 700;
}

.consider-text.bottom{
    font-size: 0.85em;
    font-weight: 500;
}