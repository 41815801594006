.profile-edit-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: -webkit-box; /* Old iOS */
    display: -moz-box; /* Old Firefox */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Old Chrome/Safari */
    display: flex;
    -webkit-justify-content: center; /* Safari 7.0+ Chrome */
    -moz-justify-content: center; /* Firefox */
    -ms-justify-content: center; /* IE 10+ */
    justify-content: center;
    -webkit-align-items: center; /* Safari 7.0+ */
    -moz-align-items: center; /* Firefox */
    -ms-align-items: center; /* IE 10+ */
    align-items: center;
    z-index: 9999;
    background-color: rgba(19, 19, 19, 0.5);
    /*-webkit-backdrop-filter: blur(10px); */
    /*backdrop-filter: blur(10px);*/
}

.checkbox-skip-image{
   width: 100%;
    display: flex;
    margin-top: 25px;
    margin-bottom: -25px;
}

.profile-cont-t3,
.profile-cont-t1{
    width: 100%;
}

.profile-container-menu {
    display: -webkit-box; /* Old iOS */
    display: -moz-box; /* Old Firefox */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Old Chrome/Safari */
    display: flex;
    min-width: 440px;
    max-width: 460px;
    z-index: 9998;
    -webkit-flex-direction: column; /* Old Chrome/Safari */
    -moz-flex-direction: column; /* Firefox */
    -ms-flex-direction: column; /* IE 10 */
    flex-direction: column;
    -webkit-justify-content: space-between; /* Safari 7.0+ Chrome */
    -moz-justify-content: space-between; /* Firefox */
    -ms-justify-content: space-between; /* IE 10+ */
    justify-content: space-between;
    -webkit-align-items: center; /* Safari 7.0+ */
    -moz-align-items: center; /* Firefox */
    -ms-align-items: center; /* IE 10+ */
    align-items: center;
    position: relative;
    overflow: hidden;
    text-align: left;
    background-color: #131313;
    color: white;
    height: fit-content;
    margin: 20px auto;
    padding: 30px;
    border-radius: 12px;
    -webkit-user-select: none; /* Chrome/Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE/Edge */
    user-select: none;
    -webkit-box-shadow: 0 2px 4px rgba(108, 108, 108, 0.85); /* Chrome/Safari */
    -moz-box-shadow: 0 2px 4px rgba(108, 108, 108, 0.85); /* Firefox */
    box-shadow: 0 2px 4px rgba(108, 108, 108, 0.85);
}


.profile-cont-t1 {
    text-align: left;
}

.profile-cont-t2 {
    flex-direction: row;
    display: inherit;
    width: 100%;
    justify-content: space-between;
    padding: 10px 0;
}

.grid-4-profile {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
}

.grid-payment-2 {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    padding: 20px 0;
}

.grid-profile-item {
    align-self: flex-start!important;
    color: #fff!important;
    border-radius: 8px!important;
    flex-direction: column!important;
    display: flex!important;
}

.progress-bar-background {
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: rgba(105, 105, 105, 1);
    z-index: 1;
}

.circle-progress-bar {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: relative;
    border: 2px rgba(105, 105, 105, 0.8) solid;
    background-color: rgb(68, 68, 68); /* White for active circles */
    z-index: 2;
}

.circle-progress-bar.active {
    border: 2px white solid;
    background-color: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.progress-bar-line {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 4px;
    background-color: #d9d9d9;
    z-index: 1;
    left: 0;
    transition: width 0.3s ease;
}

.progress-user-icon {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    transition: left 0.3s ease;
}


.profile-cont-t4 {
    flex-direction: column;
    display: inherit;
    width: 100%;
}

.act-container,
.sub-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.sub-cart-details {
    width: 60px;
    height: auto;
    padding-top: 5px;
}

.plan-type-2,
.plan-type {
    font-size: 0.9em;
    font-weight: 700;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.plan-type {
    background: linear-gradient(0deg, rgba(196, 196, 196, 0.5), rgba(196, 196, 196, 0.5)),
    linear-gradient(101.6deg, #ad4ee7 50.65%, #ac14e8 86.66%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.plan-type-2 {
    background: transparent;
    color: white;
}

.edit-settings-icon {
    cursor: pointer;
    margin-left: 5px;
    height: 16px;
    width: 16px;
}

.payment-method-container,
.active-plan-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
}

.close-modal-icon-profile {
    align-self: flex-end;
    cursor: pointer;
    margin-left: auto;

}


.cross-icon {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
}


.profile-setting-label {
    font-size: 1.5em;
    font-weight: 700;
    flex-direction: row;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    padding-bottom: 5px;
}


.profile-setting-label-2 {
    font-size: 1.5em;
    font-weight: 700;
    flex-direction: row;
    display: flex;
    width: 100%;
    justify-content: center;
    padding-bottom: 5px;
}
.char-created-label-3 {
    font-size: 1.5em;
    font-weight: 700;
    flex-direction: row;
    display: flex;
    width: 100%;
    justify-content: left;
    padding-bottom: 5px;
}
.profile-setting-sublabel {
    font-size: 1em;
    font-weight: 500;
    margin-top: 5px;
    position: relative;
    font-style: normal;
    color: #C4C4C4;
}

.plan-length {
    color: #C4C4C4;

}

.profile-setting-sublabel-1 {
    font-size: 1.125em;
    font-weight: 500;
    position: relative;
    font-style: normal;
    margin-top: 30px;
    color: white;
    margin-bottom: 20px;
}

.profile-circle {
    justify-items: center;
    text-align: center;
    border-radius: 50%;
    align-items: center;
    display: inherit;

    background-size: cover;
    width: 84px;
    min-width: 84px;
    height: 84px;
    min-height: 84px;
    cursor: pointer;


}



.free-plan {
    color: #C5C5C5;
}

.profile-image, .default-avatar {
    background-image: url('ProfileIcons/new-default-profile.svg');
    object-fit: cover;
    display: block;
    max-width: 86px;
    max-height: 86px;
    min-width: 86px;
    min-height: 86px;
    background-size: cover;
    border-radius: 50%;
    cursor: pointer;
    position: static;
    background-color: #4B3387;
    justify-content: center;
    align-items: center;
    border: 1px solid #C4C4C4;
}


.info-sub-2-label,
.info-label {
    display: flex;
    font-weight: 700;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    color: #C4C4C4;
    white-space: nowrap;
    font-size: 1em;
}


.info-sub-2-label {
    font-weight: 700;
    color: white;
    font-size: 0.95em;
    padding-top: 5px;
    padding-bottom: 5px;
}


.info-content {
    font-size: 0.9em;
    color: white;
    font-weight: 600;
    max-width: 80%;
}

.profile-setting-sublabel-2 {
    font-size: 1em;
    color: #C4C4C4;

}

.profile-setting-sublabel-bold {
    color: white;
    font-weight: 700;
    font-size: 1em;
}

.terms-link {
    margin-top: 10px;
    cursor: pointer;
    text-decoration: none;
}

.terms-link a {
    color: inherit;
    text-decoration: none;
}

.info-select-profile {
    width: 30%;
    border-radius: 5px;
    border: none;
    background-color: #b6a3e0;
    color: white;
    align-items: center;
    align-self: center;
    justify-items: center;

}


.signout-container {
    width: 100%;
    padding-top: 10px;
}

.sign-out-button {
    background: transparent;
    color: #AA87FF;
    border-radius: 5px;
    border: none;
    font-weight: 700;
    font-size: 1em;
    cursor: pointer;

}

.popup-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Background darkener */
    display: flex;
    justify-content: center;
    align-items: center;
}

.name-change-popup {
    position: relative;
    color: white;
    background-color: #131313;
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 440px;
    border: 1px solid #484848;
}

.new-name-input {
    padding: 6px 12px;
    width: 100%;
    height: 45px;
    background: #171717;
    color: white;
    border-radius: 14px;
    border: 1px solid #ccc;
    font-size: 1em;
}

.save-new-name-button {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 10px;
    padding: 12px 20px;
    transition: background-color 0.3s ease, opacity 0.3s ease;
    position: relative;
    background: #b5a2e0 linear-gradient(102.84deg, #9072D9 41.88%, #8573B0 66.26%);
    border-radius: 16px;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    color: #FFFFFF;
    cursor: pointer;
    align-items: center;
}

.save-new-name-button:hover {
    opacity: 0.9;
}

.edit-settings-icon {
    border: none;
    padding: 0;
}

.grid-profile-item{
    height: 30px;
}

.info-content-dropdown-control {
    border: transparent!important;
    border-radius: 4px!important;
    cursor: pointer!important;
    color: white!important;
    background-color: transparent!important;
    height: 30px!important;
    align-items: center!important;
    justify-content: start!important;
    transition: all 0.3s ease!important;
    display: flex!important;
    font-weight: 700!important;
    padding: 0!important;
    width: 100%!important;
}

.info-content-dropdown.is-open .info-content-dropdown-control {
    border: 1px solid #C4C4C4!important;
    padding-left: 10px!important;
    border-bottom-left-radius: 0!important;
    border-bottom-right-radius: 0!important;

}

.info-content-dropdown-control:hover {
    background-color: #0d0d0d!important;
}

.info-content-dropdown-menu {
    background-color: #131313!important;
    border: 1px solid #C4C4C4!important;
    border-radius: 0 0 8px 8px!important;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1)!important;
    width: 100%!important;
}

.info-content-dropdown-menu .Dropdown-option {
    color: #c4c4c4!important; 
    padding: 5px 10px!important;
}

.info-content-dropdown-arrow {
    position: absolute!important;
    top: 0!important;
    bottom: 0!important;
    margin: auto!important;
    display: flex!important;
    justify-content: center!important;
    border-color: #ffffff transparent transparent!important;
}

.Dropdown-option:hover {
    background-color: #383838!important;
}

@media screen and (max-width: 680px) {

    .grid-payment-2 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: space-between;
        padding: 15px 0;
    }

    .profile-container-menu {
        width: 90vw;
        min-width: 380px;
        max-width: 420px;
    }

    .profile-cont-t2 {
        flex-direction: column;
        display: inherit;
        align-items: center;
        width: 100%;
        gap: 10px;
        padding: 5px 0 20px 0;
    }

    .grid-4-profile {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        width: 100%;
        justify-content: space-evenly;
    }

    .a-straight-line, .a-straight-line-2 {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .profile-setting-sublabel {
        font-size: 0.85em;
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
    }

    .profile-setting-label {
        padding-bottom: 1px;
        font-size: 1.2em;
    }
    .char-created-label-3,
    .profile-setting-label-2 {

        font-size: 1.2em;
    }
    .default-avatar {
        margin-bottom: 0;
    }


    .plan-type,
    .plan-length,
    .info-sub-2-label {
        font-size: 0.85em;
        white-space: nowrap;
    }

    .plan-type-2 {
        font-size: 0.75em;
    }

    .sign-out-button {

        font-size: .85em;


    }
    .profile-setting-sublabel-2 {
        font-size: 0.75em;
        white-space: nowrap;
    }

    .signout-container {
        padding-top: 15px;
    }
}

